import type { FunctionsPrefix } from '@lp-lib/cloud-functions-prefix';

import { getEnsure } from './config/ensure';
import { getEnv } from './config/getEnv';
import { sentry, type SentryConfig } from './config/sentry';
import { booleanify } from './utils/common';

interface APIConfig {
  endpoint: string;
  baseUrl: string;
  baseWsUrl: string;
  internalBaseUrl: string;
  pageSize: number;
}

interface BucketConfig {
  domain: string;
}

export interface FirebaseConfig {
  apiKey: string;
  databaseUrl: string;
  databaseLoggingEnabled: boolean;
  projectId: string;
  useEmulator: boolean;
  authUrl?: string; // only used in emulator
  functionsPrefix: FunctionsPrefix;
}

interface AgoraConfig {
  appId: string;
  minVolume: number;
  codec: string;
  logLevel: number;
  enableLogUpload: boolean;
}

interface CrowdFramesConfig {
  url: string;
}

interface LogConfig {
  level: string;
  console: boolean;
  consoleFormat: 'json' | 'message-json' | 'message-lines' | 'message-only';
  http: string;
  enabledScopes: string[];

  queueFlushMs: number;
  maxEntryCountPerRequest: number | null;
  maxBackoffMs: number;
  maxDeliveryAttempts: number;
}

interface BaseTeamConfig {
  maxMembers: number;
}

interface TeamRandomizerConfig {
  countdown: number;
  animiationDuration: number;
  defaultToCurrentTeam: string;
}

interface TeamConfig extends BaseTeamConfig {
  autoRejoinTimeout: number;
  inviteCooldown: number;
  randomizer: TeamRandomizerConfig;
}

interface ChatConfig {
  streamChatKey: string;
  loadHistory: boolean;
}

interface UserConnectionConfig {
  cleanupTimeout: number;
}

interface AppConfig {
  userConnection: UserConnectionConfig;
  homeUrlOrPathname: string;
  baseUrl: string;
  marketHomeUrl: string;
  secure?: boolean | 'prod-only';
}

interface StageConfig extends BaseTeamConfig {}

interface NotificationConfig {
  limit: number;
}

interface HubspotConfig {
  subscriptionCancelSupportCallUrl: string;
  subscriptionCancelOfferAcceptedUrl: string;
  headcountUrl: string;
  eventPurchasePreCallUrl: string;
  bookDemoUrl: string;
}

interface MiscConfig {
  runAtLeast: number;
  typeFormIdLive: string;
  typeFormIdOnd: string;
  typeFormIdLive2: string;
  typeFormIdCohost: string;
  typeFormIdLiveBooking: string;
  typeFormIdCollective: string;
  typeFormIdPlanUpgrade: string;
  reCaptchaSitekey: string;
  offBoardingEnabled: boolean;
  googleClientId: string;
  intercomAppId: string;
  skipOnDGameChecklist: string;
  slackAdminToolEnabled: boolean;
  maintenance: boolean;
  gamePackImportEnabled: boolean;
  firstTimeGamePackId: string;
  lunaParkOrgId: string;
  celebrationsTagSlug: string;
  favoriteTagId: number;
  faqGroupIds: string[];
  anonymousFaqGroupIds: string[];
  onboardingHubspotUrl: string;
  introsHubspotUrl: string;
  eventPlanningHubspotUrl: string;
  consoleCtrl: boolean;
  musicPlaylistId?: string;
  live2DefaultBackgroundAssetId?: string;
  ugcTemplatesTagId: number;
}

interface KibanaQueryConfig {
  baseUrl: string;
  webAppLogIndex: string;
  venueStatsDashboardId: string;
}

interface SentimentConfig {
  cooldown: number;
  confidenceThreshold: number;
  expressions: string[];
  uploadEnabled: boolean;
  uploadLimits: number;
  baselineCapture: number;
}

interface SessionConfig {
  trackingEnabled: boolean;
  snapshotCheckInterval: number;
  realtimeSilentRefreshEnabled: boolean;
}

interface CloudHostingConfig {
  enabled: string;
}

interface SegmentConfig {
  writeKey: string;
}

interface SlackConfig {
  botUsername: string;
  botIconURL: string;
  mediaViewerEnabled: boolean;
  clientId: string;
}

interface EventConfig {
  liveGamePack: string | null;
}

interface CrossOriginStorageConfig {
  enabled: boolean;
  origin: {
    hub: string;
    client: string;
  };
}

interface ProgramConfig {
  introsRoundTimeToMinutes: number;
  waterCoolerRoundTimeToMinutes: number;
}

interface StripeConfig {
  publishableKey: string;
  minimalPMCID: string | null;
}

export interface Configuration {
  api: APIConfig;
  bucket: BucketConfig;
  firebase: FirebaseConfig;
  sentry: SentryConfig;
  agora: AgoraConfig;
  crowdFrames: CrowdFramesConfig;
  log: LogConfig;
  team: TeamConfig;
  chat: ChatConfig;
  app: AppConfig;
  stage: StageConfig;
  notification: NotificationConfig;
  misc: MiscConfig;
  sentiment: SentimentConfig;
  session: SessionConfig;
  cloudHosting: CloudHostingConfig;
  kibana: KibanaQueryConfig;
  segment: SegmentConfig;
  slack: SlackConfig;
  event: EventConfig;
  crossStorage: CrossOriginStorageConfig;
  program: ProgramConfig;
  stripe: StripeConfig;
  hubspot: HubspotConfig;
}

const env = getEnv();
const ensure = getEnsure(env);

const config: Configuration = {
  api: {
    endpoint: `${ensure('API_SERVICE')}`,
    baseUrl: `${ensure('API_SERVICE')}/api/v1`,
    baseWsUrl: `${ensure('API_WS_SERVICE')}/api/v1`,
    internalBaseUrl: `${ensure('API_SERVICE_INTERNAL')}/api/v1`,
    pageSize: Number(env.API_PAGE_SIZE || 25),
  },
  bucket: {
    domain: ensure('BUCKET_DOMAIN', 'https://assets.gadder.live'),
  },
  firebase: {
    apiKey: ensure('FIREBASE_API_KEY'),
    databaseUrl: ensure('FIREBASE_DATABASE_URL'),
    databaseLoggingEnabled: booleanify(env.FIREBASE_DATABASE_LOGGING_ENABLED),
    projectId: ensure('FIREBASE_PROJECT_ID'),
    useEmulator: booleanify(env.FIREBASE_USE_EMULATOR) || false,
    authUrl: env.FIREBASE_AUTH_URL,
    functionsPrefix: ensure('FIREBASE_FUNCTIONS_PREFIX'),
  },
  sentry: sentry(env),
  agora: {
    appId: ensure('AGORA_APP_ID'),
    codec: env.AGORA_CODEC || 'vp8',
    minVolume: Number(5),
    logLevel: Number(env.AGORA_LOG_LEVEL || 0),
    enableLogUpload: booleanify(env.AGORA_LOG_UPLOAD_ENABLED),
  },
  crowdFrames: {
    url: ensure('CROWD_FRAMES_SERVICE'),
  },
  log: {
    level: env.LOG_LEVEL || 'info',
    console: booleanify(env.LOG_CONSOLE_ENABLED),
    consoleFormat: (env.LOG_CONSOLE_FORMAT || 'message-json') as
      | 'message-json'
      | 'message-lines'
      | 'json',
    http: ensure('LOG_HTTP_ENDPOINT', ''),
    enabledScopes: (env.LOG_ENABLE_SCOPES || '').split(','),

    queueFlushMs: Number(env.LOG_QUEUE_FLUSH_MS || 5000),
    maxEntryCountPerRequest: Number(env.LOG_MAX_ENTRY_COUNT_PER_REQUEST || 10),

    /**
     * Assuming an exponential backoff (see AxiosTransport in the Logger), and a
     * 32000 maxBackoffMs, the 5th retry will hit the max of 32s.
     *
     * Example calculation:
     *
     * `(Math.pow(2, 0) * 1000 + Math.floor(Math.random() * 1000)) / 1000`
     *
     * - Retry 1: 2.238s
     * - Retry 2: 4.054s
     * - Retry 3: 8.821s
     * - Retry 4: 16.177s
     * - Retry 5: 32.000s (max)
     *
     * Using maxDeliveryAttempts of 100, that means 95 attempts will be made
     * using the maximum backoff interval of 32s. Doing some math...
     *
     * - 95 attempts * 30s = 47.5 minutes
     * - Retries 1-5 = 1.09 minutes
     *
     * Which means that if a user goes offline, they have at least 48.59 minutes
     * to come online before their logs are lost forever.
     */
    maxBackoffMs: Number(env.LOG_MAX_BACKOFF_MS || 32000),
    maxDeliveryAttempts: Number(env.LOG_MAX_DELIVERY_ATTEMPTS || 100),
  },
  team: {
    maxMembers: Number(env.TEAM_MAX_MEMBERS || 32),
    autoRejoinTimeout: Number(env.TEAM_AUTO_REJOIN_TIMEOUT || 1 * 60 * 1000), // 1m
    inviteCooldown: Number(env.TEAM_INCOOLDOWN || 1 * 30 * 1000),
    randomizer: {
      countdown: Number(env.TEAM_RANDOIMIZER_COUNTDOWN || 5),
      animiationDuration: 4,
      defaultToCurrentTeam:
        env.TEAM_RANDOIMIZER_DEFAULT_TO_CURRENT_TEAM || 'disabled',
    },
  },
  chat: {
    streamChatKey: ensure('CHAT_STREAM_CHAT_KEY'),
    loadHistory: booleanify(env.CHAT_LOAD_HISTORY),
  },
  app: {
    userConnection: {
      cleanupTimeout: Number(env.USER_CONN_CLEANUP_TIMEOUT || 5 * 60 * 1000), // 5m
    },
    homeUrlOrPathname: ensure('HOME_URL_OR_PATHNAME', '/'),
    baseUrl: ensure('APP_BASE_URL'),
    marketHomeUrl: ensure('MARKET_HOME_URL', 'https://lunapark.com'),
    secure: booleanify(env.APP_SECURE || 0),
  },
  stage: {
    maxMembers: Number(env.STAGE_MAX_MEMBERS || 8),
  },
  notification: {
    limit: 3,
  },
  misc: {
    runAtLeast: Number(env.MISC_RUN_AT_LEASE || 0),
    typeFormIdLive: ensure('TYPE_FORM_ID', 'E6VxZGJK'),
    typeFormIdOnd: ensure('TYPE_FORM_ID_OND', 'xawCbGmA'),
    typeFormIdLive2: ensure('TYPE_FORM_ID_LIVE_2', 'pklFCNzu'),
    typeFormIdCohost: ensure('TYPE_FORM_ID_COHOST', 'XovlDL8p'),
    typeFormIdLiveBooking: ensure('TYPE_FORM_ID_LIVE_BOOKING', 'bMRz8UPf'),
    typeFormIdCollective: ensure('TYPE_FORM_ID_COLLECTIVE', 'TbHyyC4k'),
    typeFormIdPlanUpgrade: ensure('TYPE_FORM_ID_PLAN_UPGRADE', 'OVILR2WA'),
    reCaptchaSitekey: ensure('RECAPTCHA_SITE_KEY'),
    offBoardingEnabled: booleanify(env.OFFBOARD_ENABLED || 1),
    googleClientId: ensure('GOOGLE_CLIENT_ID'),
    intercomAppId: ensure('INTERCOM_APP_ID', 'yz8tl4n9'),
    skipOnDGameChecklist: env.MISC_SKIP_OND_GAME_CHECKLIST || 'disabled',
    slackAdminToolEnabled: booleanify(env.SLACK_ADMIN_TOOL_ENABLED || 0),
    maintenance: booleanify(env.MAINTENANCE_ENABLED || 0),
    gamePackImportEnabled: booleanify(env.GAME_PACK_IMPORT_ENABLED || 0),
    firstTimeGamePackId: ensure('FIRST_TIME_GAME_PACK_ID'),
    lunaParkOrgId: env.LUNA_PARK_ORG_ID || '',
    celebrationsTagSlug: env.CELEBRATIONS_TAG_SLUG || '',
    favoriteTagId: Number(env.FAVORITE_TAG_ID || 1),
    faqGroupIds: (env.FAQ_GROUP_IDS || '').split(','),
    anonymousFaqGroupIds: (env.ANONYMOUS_FAQ_GROUP_IDS || '').split(','),
    onboardingHubspotUrl:
      env.ONBOARDING_HUBSPOT_URL ||
      'https://meetings.hubspot.com/totoday?embed=true',

    introsHubspotUrl:
      env.INTROS_HUBSPOT_URL ||
      'https://meetings.hubspot.com/anoop-sudhakaran/test-for-jesse?embed=true',
    eventPlanningHubspotUrl:
      env.EVENT_PLANNING_HUBSPOT_URL ||
      'https://meetings.hubspot.com/totoday?embed=true',
    consoleCtrl: booleanify(env.CONSOLE_CTRL_ENABLED || 0),
    musicPlaylistId: env.MUSIC_PLAYLIST_ID,
    live2DefaultBackgroundAssetId: env.LIVE2_DEFAULT_BACKGROUND_ASSET_ID,
    ugcTemplatesTagId: Number(env.UGC_TEMPLATES_TAG_ID || 0),
  },
  sentiment: {
    cooldown: Number(env.SENTIMENT_COOLDOWN || 60 * 1000),
    confidenceThreshold: Number(env.SENTIMENT_CONFIDENCE_THREASHOLD || 0.95),
    expressions: (env.SENTIMENT_EXPRESSIONS || 'happy,surprised').split(','),
    uploadEnabled: booleanify(env.SENTIMENT_UPLOAD_ENABLED),
    uploadLimits: Number(env.SENTIMENT_UPLOAD_LIMITS || 10),
    baselineCapture: Number(env.SENTIMENT_BASELINE_CAPTURE || 5 * 60 * 1000),
  },
  session: {
    trackingEnabled: booleanify(env.SESSION_TRACKING_ENABLED),
    snapshotCheckInterval: Number(
      env.SESSION_SNAPSHOT_CHECK_INTERVAL || 60 * 1000
    ),
    realtimeSilentRefreshEnabled: booleanify(
      env.SESSION_REALTIME_SILENT_REFRESH_ENABLED
    ),
  },
  cloudHosting: {
    enabled: env.CLOUD_HOSTING_ENABLED || 'disabled',
  },
  kibana: {
    baseUrl: ensure('KIBANA_BASE_URL', 'https://kibana.internal.gadder.live'),
    webAppLogIndex: ensure(
      'KIBANA_WEB_APP_LOG_INDEX',
      '80a66430-5c9a-11ec-961e-57e9a1c6bb41'
    ),
    venueStatsDashboardId: ensure(
      'KIBANA_VENUE_STATS_DASHBOARD_ID',
      '1e564160-852b-11ec-bdae-f5518fbae92a'
    ),
  },
  segment: {
    writeKey: ensure('SEGMENT_WRITE_KEY'),
  },
  slack: {
    botUsername: ensure('SLACK_BOT_USERNAME', 'Luna Park'),
    botIconURL: ensure(
      'SLACK_BOT_ICON_URL',
      'https://assets.golunapark.com/static/images/lp-logo.png'
    ),
    mediaViewerEnabled: booleanify(env.SLACK_MEDIA_VIEWER_ENABLED || 0),
    clientId: env.SLACK_CLIENT_ID || '',
  },
  event: {
    liveGamePack: env.EVENT_LIVE_GAME_PACK || null,
  },
  crossStorage: {
    enabled: booleanify(env.X_STORAGE_ENABLED || 0),
    origin: {
      hub: env.X_STORAGE_HUB_ORIGIN || 'http://localhost:8080',
      client: env.X_STORAGE_CLIENT_ORIGIN || 'http://localtest.me:8080',
    },
  },
  program: {
    introsRoundTimeToMinutes: Number(
      env.PROGRAM_INTROS_ROUND_TIME_TO_MINUTES || 1
    ),
    waterCoolerRoundTimeToMinutes: Number(
      env.PROGRAM_WATER_COOLER_ROUND_TIME_TO_MINUTES || 1
    ),
  },
  stripe: {
    publishableKey: ensure('STRIPE_PUBLISHABLE_KEY'),
    minimalPMCID: env.STRIPE_MINIMAL_PMC_ID || null,
  },
  hubspot: {
    subscriptionCancelSupportCallUrl:
      env.HUBSPOT_SUBSCRIPTION_CANCEL_SUPPORT_CALL_URL ||
      'https://meetings.hubspot.com/anoop-sudhakaran/test-for-jesse?embed=true',
    subscriptionCancelOfferAcceptedUrl:
      env.HUBSPOT_SUBSCRIPTION_CANCEL_OFFER_ACCEPTED_URL ||
      'https://meetings.hubspot.com/anoop-sudhakaran/test-for-jesse?embed=true',
    headcountUrl:
      env.HUBSPOT_HEADCOUNT_URL ||
      'https://meetings.hubspot.com/anoop-sudhakaran/test-for-jesse?embed=true',
    eventPurchasePreCallUrl:
      env.HUBSPOT_EVENT_PURCHASE_PRE_CALL_URL ||
      'https://meetings.hubspot.com/anoop-sudhakaran/test-for-jesse?embed=true',
    bookDemoUrl: 'https://lunapark.com/lp/schedule-your-meeting-rb',
  },
};

// Legacy
// eslint-disable-next-line import/no-default-export
export default config;
